<template>
    <figure class="equipment-specification">
        <h2>Trailer #: {{equipment.trailerNumber}}</h2>
        <h4>{{equipment.year}} {{equipment.make.name}} {{equipment.nominalLength}}' {{equipment.description}}</h4>
        <div>
            <headless-table :record="equipment" :columns="specificationsColumns"></headless-table>
        </div>
    </figure>
</template>

<script>
import HeadlessTable from '../tables/HeadlessTable.vue';

const specificationsColumns = {
    'vin' : {
        title : "VIN #",
    },
    'doorType' : {
        title: "Door Type",
    },
    'suspensionType' : {
        title: "Suspension",
    },
    'wallType' : {
        title: "Wall Type",
    },
    'plateNumber' : {
        title: "Plate #",
        format: '{registrationState} - {plateNumber}'
    },
    'gpsEquipped' : {
        title: "GPS",
        true: 'Yes',
        false: 'No'
    },
    'nextPMDate' : {
        title: "Next PM Date",
    }

};

    export default {
        components: {
            HeadlessTable
        },
        props: ["equipment"],
        data: function(){
            return {
                specificationsColumns
            };
        }
    }

</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
</style>