<template>
    {{output(record, 0, settings, column)}}
</template>

<script>
import FormatDataMixin from '../../mixins/FormatData';

    export default {
        props: [
            'record',
            'column', 
            'settings',
        ],
        setup(){
            const {output} = FormatDataMixin();
            return {output};
        }
    }
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";
td.data-error{

    > span {
        color: $red;
        position: relative;
        cursor: pointer;

        > span {
            overflow: hidden;
            width: 0;
            display: block;
            position: absolute;
            opacity: 0;
            transition: $transition-standard;
            box-shadow: $shadow-standard;
            border-radius: 5px;
            z-index: 110;

                > span {
                    display: block;
                    padding: $space-narrow $space-standard;
                    background: $white;
                    color: $font-color;
                    width: 300px;                
            }
        }
        &:hover {
            > span {
                width: 300px;
                opacity: 1;
            }
        }
    }
}
</style>