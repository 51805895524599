<template>
    <div class="service-dashboard" v-if="loaded">
        <header class="page-header">
            <div>
                <client-account-header></client-account-header>
                <h2>Service Dashboard</h2>
            </div>
            <div class="header-controls">
                <div class="form-group search">
                    <input type="text" v-model="searchTerm" placeholder="Search..." />
                </div>
                <a class="button"><i class="fa-regular fa-bars-filter"></i> Filter</a>
                <a class="button"><i class="fa-light fa-screwdriver-wrench"></i> Request Service</a>
            </div>
        </header>
        <div class="kanban-wrapper">
            <div class="kanban">

                <div class="kanban-column cards"> 
                    <div class="column-header">{{ServiceRequestStatusType[0]}}</div>
                    <template v-for="i in serviceByStatus[0]" :key="i">
                        <div 
                            v-for="equipment in serviceRequests[i].equipment" :key="`${i}-${equipment.id}`"
                            class="card service-card" 
                            :class="{selected : serviceRequests[i].id === serviceRequestSelection }"
                            @click="serviceRequestSelection = serviceRequests[i].id"
                        > 
                            <h4><span>Trailer #:</span> <format-data :record="equipment" column="trailerNumber"></format-data></h4>
                            <span>Issues: <format-data :record="equipment" column="serviceRequestIssues" :settings="{'format': `{serviceIssueType&&valueof.serviceRequest==${serviceRequests[i].id}}`}"></format-data></span>
                            <span>Request date: <format-data :record="serviceRequests[i]" column="createdDate"></format-data></span>
                        </div>
                    </template>
                </div>

                <div class="kanban-column cards"> 
                    <div class="column-header">{{ServiceRequestStatusType[1]}}</div>
                    <template v-for="i in serviceByStatus[1]" :key="i">
                        <div 
                            v-for="equipment in serviceRequests[i].equipment" :key="`${i}-${equipment.id}`"
                            class="card service-card" 
                            :class="{selected : serviceRequests[i].id === serviceRequestSelection }"
                            @click="serviceRequestSelection = serviceRequests[i].id"
                        > 
                            <h4><span>Trailer #:</span> <format-data :record="equipment" column="trailerNumber"></format-data></h4>
                            <span>Work Order #: <format-data :record="serviceRequests[i]" column="workOrders.workOrderNumber"></format-data></span>
                            <span>Issues: <format-data :record="equipment" column="serviceRequestIssues" :settings="{'format': `{serviceIssueType&&valueof.serviceRequest==${serviceRequests[i].id}}`}"></format-data></span>
                            <span>Request date: <format-data :record="serviceRequests[i]" column="createdDate"></format-data></span>
                        </div>
                    </template>
                </div>
                
                <div class="kanban-column cards"> 
                    <div class="column-header">{{ServiceRequestStatusType[2]}}</div>
                    <template v-for="i in serviceByStatus[2]" :key="i">
                        <div 
                            v-for="equipment in serviceRequests[i].equipment" :key="`${i}-${equipment.id}`"
                            class="card service-card" 
                            :class="{selected : serviceRequests[i].id === serviceRequestSelection }"
                            @click="serviceRequestSelection = serviceRequests[i].id"
                        > 
                            <h4><span>Trailer #:</span> <format-data :record="equipment" column="trailerNumber"></format-data></h4>
                            <span>Work Order #: <format-data :record="serviceRequests[i]" column="workOrders.workOrderNumber"></format-data></span>
                            <span>Issues: <format-data :record="equipment" column="serviceRequestIssues" :settings="{'format': `{serviceIssueType&&valueof.serviceRequest==${serviceRequests[i].id}}`}"></format-data></span>
                            <span>Request date: <format-data :record="serviceRequests[i]" column="createdDate"></format-data></span>
                        </div>
                    </template>
                </div>
                
                <div class="kanban-column cards"> 
                    <div class="column-header">{{ServiceRequestStatusType[3]}}</div>
                    <template v-for="i in serviceByStatus[3]" :key="i">
                        <div 
                            v-for="equipment in serviceRequests[i].equipment" :key="`${i}-${equipment.id}`"
                            class="card service-card" 
                            :class="{selected : serviceRequests[i].id === serviceRequestSelection }"
                            @click="serviceRequestSelection = serviceRequests[i].id"
                        > 
                            <h4><span>Trailer #:</span> <format-data :record="equipment" column="trailerNumber"></format-data></h4>
                            <span>Work Order #: <format-data :record="serviceRequests[i]" column="workOrders.workOrderNumber"></format-data></span>
                            <span>Issues: <format-data :record="equipment" column="serviceRequestIssues" :settings="{'format': `{serviceIssueType&&valueof.serviceRequest==${serviceRequests[i].id}}`}"></format-data></span>
                            <span>Request date: <format-data :record="serviceRequests[i]" column="createdDate"></format-data></span>
                        </div>
                    </template>
                </div>
                
                <div class="kanban-column cards"> 
                    <div class="column-header">{{ServiceRequestStatusType[4]}}</div>
                    <template v-for="i in serviceByStatus[4]" :key="i">
                        <div 
                            v-for="equipment in serviceRequests[i].equipment" :key="`${i}-${equipment.id}`"
                            class="card service-card" 
                            :class="{selected : serviceRequests[i].id === serviceRequestSelection }"
                            @click="serviceRequestSelection = serviceRequests[i].id"
                        > 
                            <h4><span>Trailer #:</span> <format-data :record="equipment" column="trailerNumber"></format-data></h4>
                            <span>Work Order #: <format-data :record="serviceRequests[i]" column="workOrders.workOrderNumber"></format-data></span>
                            <span>Issues: <format-data :record="equipment" column="serviceRequestIssues" :settings="{'format': `{serviceIssueType&&valueof.serviceRequest==${serviceRequests[i].id}}`}"></format-data></span>
                            <span>Request date: <format-data :record="serviceRequests[i]" column="createdDate"></format-data></span>
                        </div>
                    </template>
                </div>
                
                <div class="kanban-column cards"> 
                    <div class="column-header">{{ServiceRequestStatusType[5]}}</div>
                    <template v-for="i in serviceByStatus[5]" :key="i">
                        <div 
                            v-for="equipment in serviceRequests[i].equipment" :key="`${i}-${equipment.id}`"
                            class="card service-card" 
                            :class="{selected : serviceRequests[i].id === serviceRequestSelection }"
                            @click="serviceRequestSelection = serviceRequests[i].id"
                        > 
                            <h4><span>Trailer #:</span> <format-data :record="equipment" column="trailerNumber"></format-data></h4>
                            <span>Issues: <format-data :record="equipment" column="serviceRequestIssues" :settings="{'format': `{serviceIssueType&&valueof.serviceRequest==${serviceRequests[i].id}}`}"></format-data></span>
                            <span>Request date: <format-data :record="serviceRequests[i]" column="createdDate"></format-data></span>
                        </div>
                    </template>
                </div>

            </div>
        </div>





        <transition name="autocordion">
            <div class="autocordion" v-if="serviceRequestSelection">
                <div>
                    <div class="service-details details-expander">
                        <div class="service-details-wrapper">
                            <div class="service-overview">
                                <h3>
                                    <span>Service Request #</span>{{serviceRequests[serviceRequestSelection]['id']}}
                                </h3>
                                <p>Service {{serviceRequests[serviceRequestSelection].status}} for {{serviceRequests[serviceRequestSelection].equipment.length > 1 ? 'trailers': 'trailer'}}  <format-data :record="serviceRequests[serviceRequestSelection]" column="equipment.trailerNumber" :settings="{'multiple': 'list'}"></format-data></p>
                            </div>
                            <div class="short-details">
                                <div>
                                    <h4>Requestor Info</h4>
                                    <p>Requestor contact</p>
                                </div>
                                <div>
                                    <h4>In Shop or Mobile</h4>
                                    <p>Location service taking place</p>
                                </div>
                                <div>
                                    <h4>Driver information</h4>
                                    <p>Driver contact</p>
                                </div>
                                <div>
                                    <h4>Loaded</h4>
                                    <p>Hazardous material maybe</p>
                                </div>
                            </div>
                            <div class="long-details">
                                <div class="tabs">
                                    <div class="tab-controls">
                                        <a :class="{'open' : serviceDetailsTab === 0}" @click.prevent="serviceDetailsTab = 0">Service Details</a>
                                        <a :class="{'open' : serviceDetailsTab === 1}" @click.prevent="serviceDetailsTab = 1">Work Order{{serviceRequests[serviceRequestSelection].workOrders.length > 1 ? 's' : '' }}</a>
                                        <a :class="{'open' : serviceDetailsTab === 2}" @click.prevent="serviceDetailsTab = 2">Service History</a>
                                        <a :class="{'open' : serviceDetailsTab === 3}" @click.prevent="serviceDetailsTab = 3">Equipment Details</a>
                                    </div>
                                    <div class="tab-content">
                                        <div v-if="serviceDetailsTab === 0" class="service-details-tab">
                                            <figure>
                                                <headless-table 
                                                    :record="serviceRequests[serviceRequestSelection]" 
                                                    :columns="serviceDetailsTable(serviceRequests[serviceRequestSelection])"></headless-table>
                                            </figure>
                                            <figure>
                                                <table class="headless-table">
                                                    <tbody>
                                                        <transition-group name="cross-fade">
                                                            <template v-for="equipment in serviceRequests[serviceRequestSelection].equipment" :key="equipment.id">
                                                                <template v-if="equipment._loaded">
                                                                    <tr :key="equipment.id">
                                                                        <td>Trailer #</td>
                                                                        <td><strong>{{equipment.trailerNumber}}</strong></td>
                                                                    </tr>
                                                                    <tr v-if="equipment.serviceRequestIssues._loaded" :key="`${equipment.id}a`">
                                                                        <td>Issues</td>
                                                                        <td>
                                                                            <ul 
                                                                                v-html="output(
                                                                                    issuesForEquipmentService(equipment.serviceRequestIssues, equipment, serviceRequests[serviceRequestSelection]),
                                                                                    0,
                                                                                    {format: '<li>{serviceIssueType}</li>'},
                                                                                    'serviceIssueType'
                                                                                )">

                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-else :key="`${equipment.id}a1`">
                                                                        <td colspan="2">Issues Loading...</td>
                                                                    </tr>
                                                                </template>
                                                                <tr v-else :key="`${equipment.id}b`">
                                                                    <td colspan="2">Trailer Loading...</td>
                                                                </tr>
                                                            </template>
                                                        </transition-group>
                                                    </tbody>
                                                </table>
                                            </figure>
                                        </div>
                                        <div v-if="serviceDetailsTab === 1" class="service-request-details-tab">
                                            <figure>
                                                <!-- <headless-table :record="serviceRequests[serviceRequestSelection]" :columns="requestColumns1"></headless-table> -->
                                            </figure>
                                            <figure>
                                                <!-- <headless-table :record="serviceRequests[serviceRequestSelection]" :columns="requestColumns2"></headless-table> -->
                                            </figure>
                                        </div>
                                        <div v-if="serviceDetailsTab === 2" class="equipment-maintenance-tab">
                                            <!-- <equipment-maintenance :maintenance="serviceRequests[selection]['equipment']['maintenance']"></equipment-maintenance> -->
                                        </div>
                                        <div v-if="serviceDetailsTab === 3" class="equipment-details-tab">
                                            <template v-for="equipment in serviceRequests[serviceRequestSelection].equipment" :key="equipment.id">
                                                <equipment-specification :equipment="equipment"></equipment-specification>
                                            </template>
                                            <!-- <equipment-contract :contract="serviceRequests[selection]['equipment']['compliance']"></equipment-contract> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>        
        
    </div>
</template>


<script>
// import EquipmentSpecification from '../components/equipment/EquipmentSpecification.vue';
// import EquipmentContract from '../components/equipment/EquipmentContract.vue';
// import EquipmentMaintenance from '../components/equipment/EquipmentServiceHistory.vue';
// import FormatData from '../components/utilities/FormatData.vue';

import ClientAccountHeader from '../components/clientAccounts/ClientAccountHeader.vue';
import ServiceRequestModel from '../models/ServiceRequestModel';
import { reactive, ref } from '@vue/reactivity';
import axiosInstance from '../connections/AxiosInstance';
import store from '../store/store';
import ServiceRequestStatusType from '../models/ServiceRequestStatusType'
import FormatData from '../components/utilities/FormatData.vue';
import HeadlessTable from '../components/tables/HeadlessTable.vue';
import ServiceLocationType from '../models/ServiceLocationType';
import FormatDataMixin from '../mixins/FormatData';
import EquipmentSpecification from '../components/equipment/EquipmentSpecification.vue';
import database from "@/store/database";

// const serviceColumns2 = {
//     'photos': {
//         title: "Photos",
//         format: `<ul>
//                     <li>IMG1</li>
//                     <li>IMG2</li>
//         </ul>`
//     },

// };
// const requestColumns1 = {
//     'equipment.trailerNumber' : {
//         title: "Trailer #"
//     },

// }
// const requestColumns2 = {
//     'primary' : {
//         title: "Primary contact",
//     },
// }


export default {
    components: {
        // EquipmentSpecification,
        // EquipmentContract,
        // EquipmentMaintenance,
        // FormatData,
        ClientAccountHeader,
        FormatData,
        HeadlessTable,
        EquipmentSpecification,
    },
    setup(){

        //get all the status types
        const loaded = ref(false);
        const serviceRequestSelection = ref(0);
        const serviceDetailsTab = ref(0);
        const serviceByStatus = reactive({0: [], 1:[], 2:[], 3:[], 4:[], 5:[]});
        const searchTerm = ref('');

        var promises = [];
        for (let i = 0; i < 6; ++i){
            promises.push(axiosInstance({
                type: 'get',
                url: `${ServiceRequestModel.source}/Status/${i}`
            }).then((response) => {
                store.commit('setupInformation', {model: ServiceRequestModel, type: 'status', prop: 'status', spec: i});
                store.state.AppDataInformation[ServiceRequestModel.name]['status']['status'][i].push(...response.data);
                serviceByStatus[i].push(...store.state.AppDataInformation[ServiceRequestModel.name]['status']['status'][i]);
            }, (error) => {
                console.log(error);
            }));
        }
        promises.push(store.dispatch('getSeries', ServiceRequestModel));
        Promise.all(promises).then(() => {
            loaded.value = true;
            serviceRequestSelection.value = serviceByStatus[0][0];
        });

        
        const serviceRequests = database[ServiceRequestModel.name];

        const issuesForEquipmentService = (issues, equipment, service) => {

            let builder = [];
            for (let i in issues){
                if (issues[i].equipment === equipment && issues[i].serviceRequest === service){
                    builder.push(issues[i]);
                }
            }
            return builder;
        }
        
        const serviceDetailsTable = (service) => {
            let builder = {};
            builder.createdDate = {
                title: "Request created"
            };
            builder.serviceLocationType = {
                title: "Location type",
                class: 'capitalize'
            };
            if (service.serviceLocationType === ServiceLocationType[1]){
                builder.shopLocation = {
                        title: "Shop",
                        format: "<p>{shopLocation.name}<br /> {shopLocation.street}<br /> {shopLocation.city}, {shopLocation.state} {shopLocation.zipCode}</p>"
                };
            }
            if (service.serviceLocationType === ServiceLocationType[2]){
                if (service.serviceLocation.street){
                    builder.serviceLocation = {
                        title: "Service Location",
                        format: "<p>{street}<br /> {city}, {state} {zipCode}</p>"
                    };
                } else {
                    builder.serviceLocation = {
                        title: "Service Location",
                        format: "lat:{serviceLocation.latitude}, lng:{serviceLocation.longitude}"
                    };
                }
            }
            return builder;
            
        };

        
        const {output} = FormatDataMixin();

        return {
            loaded, 
            searchTerm,
            serviceByStatus, 
            ServiceRequestStatusType, 
            serviceRequests, 
            serviceRequestSelection, 
            serviceDetailsTab, 
            serviceDetailsTable, 
            issuesForEquipmentService,
            output,
        };
    }
}
</script>


<style lang="scss">
@import "../assets/scss/variables.scss";
.service-dashboard{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
    width: 100%;
    background: #FFFFFF;


    .service-card{
        h4{
            span {
                display: inline;
                font-weight: $font-regular;
            }
        }
        span{
            display: block;
        }

        margin-bottom: $space-narrow;
    }
    
    .service-details{
        z-index: 50;
        flex: 0 0 auto;
        padding: $space-wide;
        height: 600px;
        max-height: 50vh;
        
        .service-overview{
            h3 {
                span{
                    font-weight: $font-regular;
                }
            }
        }

    }
    .tab-content{
        table{
            width: 100%;
        }
        overflow-y: scroll;
    }
    .service-details-tab,
    .service-request-details-tab,
    .equipment-details-tab {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        > figure{
            width: calc(50% - 10px);
        }
    }

    .headless-table{
        td:first-child{
            width: 200px;
        }
        ul{
            text-transform: capitalize;
        }
    }

    .service-details-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        height: 100%;

        .long-details{
            flex: 1 1 auto;
            overflow: auto;
            @include custom-scroll;

            figure{
                padding-top: $space-wide;
            }
        }
    }
}

.kanban-wrapper{
    flex: 1 1 auto;
    overflow-y: scroll;
    @include custom-scroll;
}
.kanban{
    background: #FFFFFF;
    padding: 0 $space-standard $space-standard;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;

    .column-header{
        position: sticky;
        z-index: 50;
        top: 0;
        background: nth($grays, 10);
        padding: $space-standard $space-standard $space-narrow;
        margin-bottom: $space-standard;
        text-transform: capitalize;
        box-shadow: 0 4px 4px -4px rgba(0,0,0,0.5);
    }

    .kanban-column{
        position: relative;
        width: calc(#{100% / 6} - #{$space-standard});
        min-width: 280px;
        background: nth($grays, 10);
        padding: 0 $space-narrow $space-narrow;
        overflow: visible;
        margin-left: $space-standard;

    }
}



</style>